import { FC, useState } from "react"
import Feature from "./Feature"

type FeaturesProps = {
    createdBy?: boolean,
}

const Features: FC<FeaturesProps> = ({ createdBy = false }) => {   

    const featureSlides = [
        {
            title: 'AI Design Explorer',
            text: 'Test the feasibility of your design in real-time, including antenna selection, location and estimated performance, for free. Oxion enables you to tweak your design performance with the easy to use drag and drop interface, powered by our AI model. You can adjust antenna position, clearance area and board dimensions to easily decide on the layout and performance that meet your requirements.',
            image: '/images/landing/features-01.png'
        },
        {
            title: 'Projects Dashboard',
            text: 'Keep all your IoT product designs in one place, including all your adjustments and versions. Compare project design results and collaborate with your colleagues by sharing your projects and maintaining a comprehensive record of all modifications.',
            image: '/images/landing/features-02.png'
        },
        {
            title: 'Help Center and Support',
            text: 'Access Ignion\'s support resources and technical documentation on Virtual Antenna® technology that guide you through the antenna integration process.<br />We are here to ensure a smooth and effortless antenna design experience.',
            image: '/images/landing/features-03.png'
        },
        {
            title: 'Stock Availability',
            text: 'Get direct access to Ignion’s distribution channels to easily order your chosen antenna design, including antenna and matching network components.',
            image: '/images/landing/features-04.png'
        }
    ]

    const [featureActive, setFeatureActive] = useState(0)
    const [featureImageURL, setFeatureImageURL] = useState(featureSlides[0].image);

    const isMobile = () => window.innerWidth <= 576;
    
    return (
        <section id="features" className="features hideHeader">
            <div className="features--wrapper inview--fade-in">
                <div className="left">
                    {
                        createdBy 
                        ? <>
                            <div className="title">BUILT FOR ENGINEERS</div>
                            <div className="text">Get to market faster<br />with <strong>no need</strong> for a<br /><strong>physical prototype</strong></div>
                            
                            <div className="features-image image">
                                <img src={featureImageURL} alt="FEATURES" width={650} height={416} />
                            </div>
                        </>
                        : <>
                            <div className="title">FEATURES</div>
                            <div className="text">Get to market faster<br />with no need for a<br />physical prototype</div>
                            <div className="spacer"></div>
                            <div className="cta">
                                <a href="/signup" className="btn--cta" id="btTryOxion">TRY OXION NOW</a>
                            </div>

                            <div className="features-image image">
                                <img src={featureImageURL} alt="FEATURES" width={650} height={416} />
                            </div>
                        </>
                    }
                    
                </div>
                <div className="right">

                    {
                        !createdBy && <div className="tabs">
                            <div className="tab active" data-target="item-01">AVAILABLE</div>
                            {/*<div className="tab" data-target="item-02">UPCOMING</div>*/}
                        </div>
                    }
                    
                    <div className="collapse">
                        {
                            featureSlides.map((feature,index) => <Feature key={`f-${feature.image}`} id={`${index}`} title={feature.title} text={feature.text} image={feature.image} active={index === featureActive} handleClick={(e) => { 
                                setFeatureImageURL(feature.image) 
                                if(!isMobile()) {
                                    setFeatureActive(index)
                                }
                            }} />)
                        }
                    </div>

                    <div className="features-image features-image-mobile image">
                        <img src={featureImageURL} alt="FEATURES" width={650} height={416} />
                    </div>

                </div>
            </div>
        </section>
    )
}


export default Features