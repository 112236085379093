import { FC, useEffect, useState } from "react"
import { navigateToExternalUrl } from "@/lib/utils"
import clsx from "clsx"
import { isValidEmail } from "@/lib/validation"

const Updates: FC = () => {  
    
    const [emailSubscribe, setEmailSubscribe] = useState('')
    const [isLegalChecked, setIsLegalChecked] = useState(false)
    const [disableSubscribe, setDisableSubscribe] = useState(true)

    const openSubscriptionForm = () => {
        navigateToExternalUrl(`https://ignion.us14.list-manage.com/subscribe?u=3e6c9d0f013a3b07d07562bda&id=20986e8cc4&MERGE0=${emailSubscribe}`)
    }

    useEffect(() => {
        setDisableSubscribe(!(emailSubscribe !== '' && isLegalChecked && isValidEmail(emailSubscribe)))
    }, [emailSubscribe, isLegalChecked]);
    
    return (
        <section id="updates" className="updates">
            <div className="updates--wrapper inview--fade-in">
                <div className="left">
                    <div className="title">
                        Do not miss the latest features
                    </div>
                    <form id="formUpdated">
                        <div className="input">
                            <input type="text" placeholder="Your email" value={emailSubscribe} onChange={(e) => setEmailSubscribe(e.currentTarget.value)} />

                            <div className="button"><button className={clsx('btn--submit', disableSubscribe && 'disabled')} type="button" disabled={disableSubscribe} onClick={(e) => openSubscriptionForm()} aria-label="KEEP ME UPDATED">KEEP ME UPDATED</button></div>
                        </div>

                        <div className="checkbox">
                            <input type="checkbox" id="legal" name="legal" value="1" onChange={(e) => { setIsLegalChecked(e.currentTarget.checked) }} />
                            <label htmlFor="legal">By submitting, you acknowledge Ignion <a href="https://ignion.io/privacy-policy/" target="_blank">privacy policy</a>.</label>
                        </div>
                    </form>
                </div>
                <div className="right">
                    <div className="title">Any suggestions? </div>
                    <div className="text">Share them with us!</div>
                    <a className="link" href="mailto:info@ignion.io">info@ignion.io</a>
                </div>
            </div>
        </section>
    )
}


export default Updates