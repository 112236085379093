import { FC, useEffect, useState } from "react"
import clsx from "clsx"

type GoToTopProps = {
    changetoRed?: boolean
}

const GoToTop: FC<GoToTopProps> = ({ changetoRed = false }) => {  

    const colorSVG = changetoRed ? '#FC3344' : '#0000FF'

    const [isOnTopVisible, setIsOnTopVisible] = useState(false)

    useEffect(() => {
        const checkVisibilityOnTop = () => {
            const btnOnTop = document.querySelector(".landing-oxion .back-on-top") as HTMLElement;
    
            if(btnOnTop) {
                const element = document.querySelector('#features') as HTMLElement;
                const position = element?.getBoundingClientRect();
    
                setIsOnTopVisible(position && position.top < 0)
            }
        }

        window.addEventListener('scroll', checkVisibilityOnTop);

        return () => {
            window.removeEventListener('scroll', checkVisibilityOnTop);
        };
    }, []);
    
    return (
        <div className={clsx('back-on-top', isOnTopVisible && 'active')}>
            <a href="#intro">
                <span>Go to top</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill="none">
                    <circle cx="23" cy="23" r="23" fill={colorSVG}/>
                    <path d="M22.9995 34.8185L22.9995 16.4894" stroke="#F2F2F2" strokeWidth="1.57143" strokeLinecap="square" strokeLinejoin="round"/>
                    <path d="M13.998 24.818L22.998 15.818L31.998 24.818" stroke="#F2F2F2" strokeWidth="1.57143" strokeLinecap="square" strokeLinejoin="round"/>
                    <path d="M12 11.8182H34" stroke="#F2F2F2" strokeWidth="1.57143" strokeLinecap="square" strokeLinejoin="round"/>
                </svg>
            </a>
        </div>
    )
}


export default GoToTop